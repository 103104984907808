@import "../../_globalColor";

.highlights-heading {
  font-size: 56px;
  font-weight: 400;
}

.highlights-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 0px 0 0 0;
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 0px;
}

.highlights-card-container {
  width:100%;
}

.slide-div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-div {
  float: left;
  width: 65%;
  overflow: hidden;
}
.img1 {
  height: 50%;
  position: absolute;
  top: 0px;
}
.img2 {
  height: 50%;
  position: absolute;
  bottom: 0px;
}
.desc-div {
  width: 40%;
  position: absolute;
  top: 20%;
  left: 300px;
  padding-left: 20%;
}
.slide-title {
  font-size: 36px;
  font-weight: bold;
}
.slide-subtitle {
  font-size: 16px;
}
.slide-body {
  font-size: 16px;
}

.graph {
  aspect-ratio: 3/2;
}

/* Media Query */

@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

@media (max-width: 1380px) {
  .highlights-card-container {
    padding-top: 0px;
  }
  .highlights-heading {
    font-size: 40px;
  }
  .highlights-text-subtitle {
    font-size: 18px;
    text-align: center;
  }
}

.thatsTheJoke{
  margin-left: 80%;
}

@media (max-width: 768px) {
  .img1 {
    top: 50%;
    margin-top: 35px;
    height: auto;
    width: 100%;
  }
  .img2 {
    display: none;
  }
  .desc-div {
    width: 80%;
    position: absolute;
    left: 10%;
    top: 0px;
    padding-top: 70px;
    padding-left: 0px;
  }
  .graph {
    height: 50%;
  }
  .skills-container {
    flex-direction: column;
  }
  .skills-bar {
    line-height: 3rem;
    margin: 20px;
  }
  .skills-image {
    display: none;
    order: 2;
  }
  .slide-body {
    display: none;
  }
  .highlights-card-container {
    padding-top: 0px;
  }
  .highlights-heading {
    font-size: 30px;
    text-align: center;
  }
  .highlights-text-subtitle {
    font-size: 16px;
  }
}
